<template>
  <div class="container mx-auto p-8">
    <w-top-bar class="text-2xl">Participantes</w-top-bar>
    

    <div class="flex justify-between mx-4 mt-4">
  <div class="flex items-center">
    <div class="w-16 h-16 rounded-full overflow-hidden">
      <img :src="event.image" alt="Imagen en miniatura redonda" class="w-full h-full object-cover">
    </div>
    <div class="ml-4">
      <div class="text-3xl font-medium leading-5 text-gray-900">{{ event.titulo }}</div>
      <div class="text-2xl leading-5 mt-3 text-purple-600 capitalize">{{ event.date | formatDate }}</div>
      <div class="mt-2">
        <div v-if="event.tipo == 'presencial'" class="text-xl leading-5 text-black">
          Evento Presencial
        </div>
        <div v-if="event.tipo == 'streamings'" class="text-xl leading-5 text-black">
          Evento en línea
        </div>
      </div>
    </div>
  </div>
  
  <div class="flex items-center">
    <!-- Aquí puedes colocar el icono SVG redondo de dirección -->
    <svg class="w-6 h-6 mr-2 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
    </svg>
    <div class="text-1xl font-medium leading-5 text-gray-900">{{ event.direccion }}</div>
      <!-- <div class="text-2xl leading-5 mt-3 text-gray-600 capitalize">{{ event.date | formatDate }}</div> -->
  </div>
  
  <div class="text-right">
    <div class="flex flex-col justify-center">
       <div class="text-right py-4">
                <div class="flex items-center mt-2" v-if="event.estado == 'en-venta'">
                    <div class="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                    <p class="text-2xl">En venta</p>
                </div>
                <div class="flex items-center mt-2" v-if="event.estado == 'agotado'">
                    <div class="w-4 h-4 rounded-full bg-yellow-500 mr-2"></div>
                    <p class="text-2xl">Agotado</p>
                </div>
                <div class="flex items-center mt-2" v-if="event.estado == 'proximamente'">
                    <div class="w-4 h-4 rounded-full bg-gray-500 mr-2"></div>
                    <p class="text-2xl">Próximamente</p>
                </div>
                <div class="flex items-center mt-2" v-if="event.estado == 'cerrado'">
                    <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
                    <p class="text-2xl">Cerrado</p>
                </div>
                <div class="flex items-center mt-2" v-if="event.estado == 'cancelado'">
                    <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
                    <p class="text-2xl">Cancelado</p>
                </div>
            </div>
    </div>
  </div>
</div>


    <div class="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 my-4 mx-4" >
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 my-4">
            <div class="w-full lg:w-1/5">
                <div class="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-800">
                    <div class="flex items-center">
                        <div class="flex flex-col justify-center">
                            <div class="text-lg">Ventas</div>
                            <div class="text-lg">$386,000.00</div>
                            <div class="text-sm text-gray-400">$411,000.00 gross sales</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/5">
                <div class="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-800">
                    <div class="flex items-center">
                        <div class="flex flex-col justify-center">
                            <div class="text-lg">Tickets Sold</div>
                            <div class="text-lg">42/500</div>
                            <div class="text-sm text-gray-400">42 paid . 0 free</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/5">
                <div class="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-800">
                    <div class="flex items-center">
                        <div class="flex flex-col justify-center">
                            <div class="text-lg">Page Views</div>
                            <div class="text-lg">3303</div>
                            <div class="text-sm text-gray-400">82 from Eventbrite</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/5">
                <div class="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-800">
                    <div class="flex items-center">
                        <div class="flex flex-col justify-center">
                            <div class="text-lg">12658</div>
                            <div class="text-sm text-gray-400">Orders</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/5">
                <div class="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-800">
                    <div class="flex items-center">
                        <div class="flex flex-col justify-center">
                            <div class="text-lg">$948'560</div>
                            <div class="text-sm text-gray-400">Revenue</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="border-b border-gray-200 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li class="mr-2" @click="columna = true">
          <a href="#" :class="{
              'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg group': true,
              'inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group': columna
          }">
            <svg class="w-4 h-4 mr-2" :class="{'text-blue-600': columna}" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
            </svg>
            Ticket Validados
          </a>
        </li>
        <li class="mr-2" @click="columna = false">
          <a href="#" :class="{
              'inline-flex items-center justify-center p-4 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group': true,
              'dark:text-gray-300 group-hover:text-gray-500 dark:group-hover:text-gray-300 text-blue-600 border-b-2 border-blue-600': !columna
          }" aria-current="page">
            <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
            </svg>
            Ticket Canjeados
          </a>
        </li>
      </ul>
    </div>

     <div v-if="columna">
            <div class="flex flex-col mt-4">
                <div class="-my-2 sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class=" border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50" style="width: 200px;">
                                            Datos
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Pago
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Fecha de Pago
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase bg-gray-50">
                                            Estado
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase bg-gray-50">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-300">
                                    <tr v-for="participant in validos" :key="participant._id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.nombre }} {{ participant.apellido }}</div>
                                            <div class="text-sm leading-5 text-gray-500">{{ participant.email }}</div>
                                            <!-- Otras propiedades del participante -->
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.pago }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.fecha_de_pago }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-center">
                                            <!-- <div class="text-sm font-medium leading-5 text-gray-900 bg-green-400 ">{{ participant.estado }}</div> -->
                                            <div class="text-sm font-medium  text-gray-900 bg-green-200  rounded-lg "><button class="btn btn-sm btn-success  uppercase">{{participant.estado}}</button></div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-center">
                                            <!-- <button @click="viewTicket(participant)" class="text-sm text-blue-500 cursor-pointer hover:text-blue-600">
                                              Canjear Entrada
                                            </button> -->
                                            <div class="flex items-center justify-between ">
                                            <router-link v-tooltip="'Checkin'" class="flex items-center cursor-pointer text-center m-auto" :to="{
                                          name: 'eventos-id',
                                          params: {
                                            id: event._id,
                                          },
                                        }">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2 17h-2v-4h2v4zm3 7v-2h-3v-3h-2v5h5zm6-2h-4v2h4v-2zm-11-11h2v-3h3v-2h-5v5zm24 .044c0 1.64-1.352 2.956-3.146 2.956-.533 0-1.083-.119-1.605-.373-1.511-.731-2.296-2.333-1.943-3.774.203-.822-.23-.934-.891-1.253l-11.036-5.341s1.322-1.812 2.76-1.117c.881.427 4.424 2.136 7.477 3.617l.766-.368c.662-.319 1.094-.43.895-1.252-.351-1.442.439-3.043 1.952-3.77.52-.251 1.067-.369 1.595-.369 1.799 0 3.147 1.32 3.147 2.956 0 1.23-.766 2.454-2.032 3.091-1.266.634-2.15.14-3.406.75l-.394.19.431.21c1.254.614 2.142.122 3.404.759 1.262.638 2.026 1.861 2.026 3.088zm-5.133-7.011c.381.795 1.459 1.072 2.406.617.945-.455 1.405-1.472 1.027-2.267-.381-.796-1.46-1.073-2.406-.618-.946.455-1.408 1.472-1.027 2.268zm-2.834 2.819c0-.322-.261-.583-.583-.583-.321 0-.583.261-.583.583s.262.583.583.583c.322.001.583-.261.583-.583zm5.272 2.499c-.945-.457-2.025-.183-2.408.611-.381.795.078 1.814 1.022 2.271.945.458 2.024.184 2.406-.611.382-.795-.075-1.814-1.02-2.271zm-6.305 12.649h-2v2h4v-5h-2v3zm.861-9h-.861v4h2v-2.51c-.471-.432-.847-.942-1.139-1.49zm-10.528-2.297s1.317 1.815 2.755 1.124l6.598-3.181-2.539-1.229-6.814 3.286z"/></svg>
                                            </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!columna">
            <div class="flex flex-col mt-4">
                <div class="-my-2 sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class=" border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50" style="width: 200px;">
                                            Datos
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Pago
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Fecha de Pago
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Estado
                                        </th>
                                        <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-300">
                                    <tr v-for="participant in canjeados" :key="participant._id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.nombre }} {{ participant.apellido }}</div>
                                            <div class="text-sm leading-5 text-gray-500">{{ participant.email }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.pago }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm font-medium leading-5 text-gray-900">{{ participant.fecha_de_pago }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-center">
                                        
                                            <div class="text-sm font-medium leading-5 text-gray-900 bg-red-400  rounded-lg "><button class="btn btn-sm btn-success  uppercase">{{participant.estado}}</button></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
    layout: "default",

    data() {
        return {
            columna: true,
            // columna_dos: false,
            filter: "approved",
            participantes: null,
            validos: null,
            canjeados: null,
            event: null,
            orderTypes: [
                { label: "Aprobados", key: "approved", query: { status: "paid" } },
                { label: "Pendientes", key: "pending", query: { status: null } },
                {
                    label: "Rechazados",
                    key: "cancelled",
                    query: { status: "cancelled" },
                },
                {
                    label: "Devoluciones",
                    key: "refunded",
                    query: { status: "refunded" },
                },
                { label: "Archivados", key: "archived", query: { archive: true } },
            ],
        };
    },
    async created() {
        await this.fetchTickets();
        await this.fetchEvent();
        // await this.find();
    },
    methods: {
        //esto hay que cambiarlo por participantes cuando este creada la api
        // ...mapActions("tickets", ["find"]),
        ...mapActions("tickets", {
            findParticipantes: "find"
        }),
        ...mapActions("eventos", {
            findEventos: "find"
        }),
        // ...mapActions("participants", ["fetchParticipants"]),
        editEvent(event) {
            console.log("Editing event:", event);
            // Implement the edit functionality
        },
        deleteEvent(eventId) {
            console.log("Deleting event:", eventId);
            // Implement the delete functionality
        },
        async fetchTickets() {
            try {
                const response = await this.findParticipantes({ query: { evento_id: this.$route.params.id } });

                // const response = await this.find({ query: { evento_id: "64d668d7a82e179be902cf38" } });
                this.participantes = response.data;

                this.canjeados = this.participantes.filter((participante) => {
                    return participante.estado == "canjeado";
                });

                this.validos = this.participantes.filter((participante) => {
                    return participante.estado == "valido";
                });
                console.log("Participantes:", response.data);
            } catch (error) {
                console.error("Error fetching event:", error);
            }
        },
        async fetchEvent() {
            try {
                const response = await this.findEventos({ query: { _id: this.$route.params.id, $limit: 1 } });
                this.event = response.data[0];
                console.log("Evento:", response.data[0]);
            } catch (error) {
                console.error("Error fetching event:", error);
            }
        },
    },
    filters: {

        formatDate(value) {
            return moment(value).format("MMMM Do YYYY, h:mm:ss A");
        },

    },
    computed: {
        // Función computada que recibe el texto y lo formatea
        formatText() {
            return (text) => {
                const maxLength = 3; // Máxima longitud deseada

                if (text.length <= maxLength) {
                    return text;
                } else {
                    return text.slice(0, maxLength) + " ...";
                }
            };
        }
    }
};
</script>
